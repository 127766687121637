/* eslint-disable camelcase */
import React, { useState, useEffect } from "react"
import { useStoreState, useStoreActions } from "easy-peasy"
import { graphql } from 'gatsby'
import urljoin from 'url-join'
import { hasPath } from 'ramda'
import Layout from '@layouts/MainLayout'
import Container from '@layouts/Container'
import Box from '@lib/ui/core/Box'
import SEO from '@components/SEO'
import AppLink from '@components/AppLink'
import JsonLd from '@components/JsonLd'
import OfferList from '@components/Offer/List'
import OfferShowMore from '@components/Offer/ShowMore'
import { parseOfferSubcategory, parseOffer } from '@services/prismic/helpers'
import itemListSchema from '@utils/json-ld/item-list'
import { linkResolver } from '@utils/link-resolver'
import SearchInput from "@components/Search/SearchInput"
import Pagination from '@material-ui/lab/Pagination'
import PaginationItem from '@material-ui/lab/PaginationItem'
import Link from '@components/Link'
import Typography from '@lib/ui/core/Typography'
import Grid from '@lib/ui/core/Grid'
import DfpAdSlot from '@components/Ads/DfpAdSlot'
import FacebookPage from "@components/Widget/FacebookPage"
import SubcategoryBlock from '@components/Offer/SubcategoryBlock'

const OfferSubcategoryPage = ({ data, location }) => {
  const doc = data && data.offerSubcategory
  if (!doc) return null
  const offerSubcategory = parseOfferSubcategory(doc)

  const { meta_keywords, meta_description, og_image } = offerSubcategory

  const offersDoc = data && data.offers && data.offers.nodes
  const totalCount = data && data.offers && data.offers.totalCount
  const { currentPage, pageCount } = data && data.offers && data.offers.pageInfo
  const offers = parseOffer(offersDoc)
  const lastPublicationDate = offers && offers[0] && offers[0].last_publication_date

  const offerIds = offers.map(({ prismicId }) => (prismicId))

  const { siteMetadata } = data.site

  const toggleSearchDrawer = useStoreActions(
    ({ offerSearch }) => offerSearch.toggleSearchDrawer
  )

  useEffect(() => {
    toggleSearchDrawer(false)
  }, [])

  const path = linkResolver(offerSubcategory)

  return (
    <Layout headerTitle={`${offerSubcategory.name} (${totalCount})`} type="offer">
      <SEO
        title={hasPath(['offer_category', 'name'])(offerSubcategory) ? `${offerSubcategory.name} | ${offerSubcategory.offer_category.name}` : offerSubcategory.name}
        description={meta_description}
        keywords={meta_keywords}
        image={og_image && og_image.url}
      />
      <AppLink path={location.pathname} />
      <JsonLd>
        {itemListSchema({
          itemListElement: offers.map((offer) => ({
            url: urljoin(siteMetadata.siteUrl, linkResolver(offer)),
          })),
        })}
      </JsonLd>
      <Container>
        <Box display="none" position="relative" mt="-1.5rem" mb={{ xs: 2.5, sm: 4 }}>
          <SearchInput height="10rem" />
        </Box>
      </Container>
      <Container>
      <Box mb={2} mt={4}>
        <Typography variant="h2">{offerSubcategory.name}</Typography>
      </Box>
      <Box mx="auto" my={{ xs: 1.5, sm: 3 }} data-ele-section="posts-list">
        <Grid container spacing={2}>
          {/* {posts.map((post) => <Box key={post.prismicId} mb={2.5}><Post post={post} fullWidth /></Box>)} */}
          <Grid item xs={12} sm={8} md={8}>
            <OfferList offers={offers} fullWidth />
             <Box textAlign="center" spacing={2} display="flex" justifyContent="center">
              <Pagination
                page={currentPage}
                count={pageCount}
                renderItem={(item) => (
                  <PaginationItem
                    component={Link}
                    to={`${path}/${item.page === 1 ? '' : `${item.page}`}`}
                    {...item}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={0} sm={4} md={4} direction="column">
            <Box mb={4} textAlign="center" display={{ xs: 'none', md: 'block' }}>
              <DfpAdSlot adUnit="Web_JetsoBee/jetsobee_ros_lrec_300x250" sizes={[300, 250]} />
            </Box>
            <Box mb={4} textAlign="center" display={{ xs: 'none', md: 'block' }}>
              <DfpAdSlot adUnit="Web_JetsoBee/jetsobee_ros_lrec4_300x250" sizes={[300, 250]} />
            </Box>
            <Box mb={4} textAlign="center" display={{ xs: 'none', md: 'block' }}>
              <FacebookPage />
            </Box>
            <Box mb={4} textAlign="center" display={{ xs: 'none', md: 'block' }}>
              <SubcategoryBlock />
            </Box>
          </Grid>
        </Grid>
      </Box>
      </Container>
    </Layout>
  )
}

export default OfferSubcategoryPage

export const query = graphql`
  query OfferSubcategoryListQuery($id: ID!, $limit: Int!, $skip: Int!, $today: Date) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    offerSubcategory: prismicOfferSubcategory(prismicId: { eq: $id }) {
      data {
        name
        offer_category {
          document {
            ... on PrismicOfferCategory {
              data {
                name
              }
              type
              uid
            }
          }
        }
        meta_keywords
        meta_description
        og_image {
          url
        }
      }
      prismicId
      uid
      type
    }
    offers: allPrismicOffer(
      limit: $limit
      skip: $skip
      sort: { fields: data___date, order: DESC }
      filter: {
        data: {
          offer_subcategory: {
            id: {
              eq: $id
            }
          },
          date: {
            lte: $today
          }
        }
      }
    ) {
      totalCount
      nodes {
        data {
          offer_category {
            document {
              ... on PrismicOfferCategory {
                data {
                  name
                }
                uid
                type
              }
            }
          }
          offer_subcategory {
            document {
              ... on PrismicOfferSubcategory {
                uid
                type
                data {
                  name
                  offer_category {
                    uid
                    type
                  }
                  image {
                    url
                  }
                  image_color
                }
              }
            }
          }
          featured_images {
            image {
              url
              thumbnails {
                thumbnail {
                  fluid(maxWidth: 240) {
                    ...GatsbyPrismicImageFluid_noBase64
                  }
                }
              }
              alt
            }
          }
          merchants {
            merchant {
              document {
                ... on PrismicMerchant {
                  uid
                  data {
                    name
                  }
                }
              }
            }
          }
          banks {
            bank {
              document {
                ... on PrismicBank {
                  uid
                  prismicId
                  data {
                    name
                  }
                }
              }
            }
          }
          credit_cards {
            credit_card {
              document {
                ... on PrismicCreditCard {
                  uid
                  prismicId
                  data {
                    name
                    bank {
                      id
                    }
                    card_image {
                      alt
                      url
                      dimensions {
                        width
                        height
                      }
                      fixed(height: 42){
                        ...GatsbyPrismicImageFixed_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
          short_description {
            text
          }
          tagged {
            tag {
              uid
              type
              document {
                ... on PrismicTag {
                  id
                  data {
                    name
                  }
                }
              }
            }
          }
          title
          to(formatString: "LL", locale: "zh-tw")
        }
        prismicId
        last_publication_date
        uid
        type
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
`
