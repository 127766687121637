import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import urljoin from 'url-join'

function AppLink({ path }) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          appName
          iosAppUrl
          iosAppStoreId
          andriodAppUrl
          androidPackageName
        }
      }
    }
  `)

  return (
    <Helmet
      meta={[
        {
          property: `al:ios:url`,
          content: `${encodeURI(urljoin(site.siteMetadata.iosAppUrl, path))}`,
        },
        {
          property: `al:ios:app_name`,
          content: `${site.siteMetadata.appName}`,
        },
        {
          property: `al:ios:app_store_id`,
          content: `${site.siteMetadata.iosAppStoreId}`,
        },
        {
          property: `al:android:url`,
          content: `${encodeURI(urljoin(site.siteMetadata.andriodAppUrl, path))}`,
        },
        {
          property: `al:android:app_name`,
          content: `${site.siteMetadata.appName}`,
        },
        {
          property: `al:android:package`,
          content: `${site.siteMetadata.androidPackageName}`,
        },
      ]}
    />
  )
}

export default AppLink
