const itemListSchema = ({ itemListElement }) => ({
  "@context": "http://schema.org",
  "@type": "ItemList",
  itemListElement: itemListElement.map((item, index) => ({
    "@type": "ListItem",
    position: index + 1,
    ...item,
  })),
})

export default itemListSchema
